import React from 'react';
import styles from "../BoxHomeContent/BoxHomeContent.module.scss";
import logo from "../../Assets/logo.png";

function BoxHome() {
  return (
    <div className={styles.BoxContainer}>
      <div className={`${styles.box1} ${styles.boxes}`}>
        <h3>Natural</h3>
        <h3>Language</h3>
        <h3>Input</h3>
        <ul className={styles.boxList}>
          <li>Human natural language conversational programming system.</li>
        </ul>  
      </div>
      <div className={`${styles.boxLineDot} ${styles.boxLineDot1}`}>
        <div className={styles.dot}></div>
        <div className={styles.line}></div>
        <div className={styles.dot}></div>
      </div>
      <div className={`${styles.box2} ${styles.boxes}`}>
        <h3>
          <img src={logo} className={styles.imgLogo} alt="Newto logo" />
        </h3>
        <ul className={styles.boxList}>
          <li>AI suggestions</li>
          <li>Visual Programming</li>
          <li>Automatic software documentation</li>
          <li>Automatic frontend app generation</li>
          <li>Automatic backend app generation</li>
        </ul>
      </div>
      <div className={`${styles.boxLineDot} ${styles.boxLineDot2}`}>
        <div className={styles.dot}></div>
        <div className={styles.line}></div>
        <div className={styles.dot}></div>
      </div>
      <div className={`${styles.box3} ${styles.boxes}`}>
        <h3>Software</h3>
        <ul className={styles.boxList}>
          <li>
          Newto's disruptive AI-based technology automatically generates fully documented software by utilizing the user's input in a natural language format.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default BoxHome;
