import React, { useState, useEffect } from "react";
import Styles from "./Notification-Porfolio-Box.module.scss";

// import { ReactComponent as Axeria } from "../../Assets/Clients/axeria.svg";


import dauneExpert from "../../Assets/Clients/Black/dauneExpert.png";
import { ReactComponent as Bugsy } from "../../Assets/Clients/Black/bugsy.svg";
import { ReactComponent as Intaro } from "../../Assets/Clients/Black/intaro.svg";
// import { ReactComponent as Velary } from "../../Assets/Clients/Black/velary.svg";

function NotificationBox() {
  const initialShowPortfolio = window.innerHeight > 1400;

  const [showNotification, setShowNotification] =
    useState(initialShowPortfolio);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      if (showNotification) {
        return;
      }

      const scrollPosition = window.scrollY;
      const desiredPosition = 200; // Change this value as needed

      // Check if the scroll position is greater than or equal to the desired position
      setShowNotification(scrollPosition >= desiredPosition);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showNotification && (
        <div className={Styles.notificationContainer}>
          <div className={Styles.notificationContent}>
            <div className={Styles.inputContainer}>
              <h4>STAY TUNED</h4>
              <p>We're putting the finishing touches. We'll let you know when we are ready.</p>
              <form>
                <label>
                  <input
                    className="notificationInput"
                    type="text"
                    placeholder="Enter your email here"
                  />
                </label>
                <button type="submit">NOTIFY ME</button>
              </form>
            </div>
            <div className={Styles.clientsContainer}>
              <div className={Styles.LogoContainer}>
                <div className={Styles.LogoContentTop}>
                <img src={dauneExpert} className={Styles.clientLogo} alt="logo daune expert" />
                <Bugsy className={Styles.clientLogo}/>
                </div>
                <div className={Styles.LogoContentBottom}>
                {/* <Velary className={Styles.clientLogo}/> */}
                <Intaro className={Styles.clientLogo}/>
                </div>
              </div>
              <div style={{textAlign:"center",fontsize:"16px",fontWeight:"500"}}>Early Adopters</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default NotificationBox;
