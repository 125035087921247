import React, { useState, useEffect } from "react";
import { ReactComponent as CirclePlus } from "../../Assets/Icon/CirclePlus.svg";

import Styles from "../Features/Features.module.scss";

function FeaturesBanner() {
  const initialShowFeatures = window.innerHeight > 1100;

  const [showFeatures, setShowFeatures] = useState(initialShowFeatures);

  useEffect(() => {
    // Function to handle the scroll event
    const handleScroll = () => {
      if (showFeatures) {
        return;
      }

      const scrollPosition = window.scrollY;
      const desiredPosition = 100; // Change this value as needed

      // Check if the scroll position is greater than or equal to the desired position
      setShowFeatures(scrollPosition >= desiredPosition);
    };

    // Attach the event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div>
      {showFeatures && (
        <div className={Styles.featuresContainer}>
          <div className={Styles.featuresContent}>
            <div className={Styles.Box1}>
              <h2>KEY</h2>
              <h2>FEATURES</h2>
            </div>
            <div className={Styles.Box3}>
            <CirclePlus />
            <CirclePlus />
            <CirclePlus />
            <CirclePlus />
            </div>
            <div className={Styles.Box2}>
              <div className={`${Styles.TextBox} ${Styles.TextBox1}`}>
                <span>
                  <CirclePlus />
                </span>
                <span>No coding skills required to create any app.</span>
              </div>
              <div className={`${Styles.TextBox} ${Styles.TextBox2}`}>
                <span>
                  <CirclePlus />
                </span>
                <span>
                  Fully integrated with big cloud providers like Azure, AWS,
                  etc.
                </span>
              </div>
              <div className={`${Styles.TextBox} ${Styles.TextBox3}`}>
                <span>
                  <CirclePlus />
                </span>
                <span>
                  <p style={{marginBottom:"0px"}}>
                    You are the owner of the app created with Newto, which
                    operates entirely independently of Newto.
                  </p>
                  <p style={{marginBottom:"0px"}}>
                    The only instance where you require Newto is for app
                    maintenance updates.
                  </p>
                </span>
              </div>
              <div className={`${Styles.TextBox} ${Styles.TextBox4}`}>
                <span>
                  <CirclePlus />
                </span>
                <span>
                  Automatic pipeline deployment for code application and data.
                </span>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default FeaturesBanner;
