import React, { useEffect, useState } from "react";

// import VideoBackground from "../../Components/VideoBackground/VideoBackground.js";
import ScrollAnimation from "../../Components/ScrollAnimation/ScrollAnimation.js";
import ScrollAnimationMobile from "../../Components/ScrollAnimationMobile/ScrollAnimationMobile.js";
import BoxHomeContent from "../../Components/BoxHomeContent/BoxHomeContent.js";
// import Portfolio from "../../Components/Porfolio/Portfolio.js";
// import NotificationBox from "../../Components/NotificationBox/NotificationBox.js";
import NotificationPorfolioBox from "../../Components/Notification-Porfolio-Box/Notification-Porfolio-Box.js";
import Features from "../../Components/Features/Features.js";

import styles from "./Home.module.scss";

const Home = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleWindowResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleWindowResize);

    // Check on component mount
    handleWindowResize();

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);
  return (
    <div className={styles.homeContainer}>
     {isMobile ? <ScrollAnimationMobile /> : <ScrollAnimation />}
      {/* <VideoBackground/> */}
      <div className={styles.homeContent}>
        <div className={styles.homeTitle}>
          <div className={styles.homeTitleText}>The Future</div>
          <div className={styles.homeTitleText}>Of Code</div>
          <div className={styles.homeTitleText}>Is No-Code.</div>
        </div>
        <BoxHomeContent />
        <Features />
        {/* <Portfolio /> */}
        {/* <NotificationBox /> */}
        <NotificationPorfolioBox/>
      </div>
    </div>
  );
};

export default Home;




