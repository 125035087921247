import "../ScrollAnimation/ScrollAnimation.css";
import React, { useState, useEffect, useRef } from "react";

const ScrollAnimation = () => {
  const imageList = importAll(
    require.context(
      "../../Assets/Animation/NoBlurr",
      false,
      /\.(png|jpe?g|svg)$/
    )
  );
  const imageListBlurr = importAll(
    require.context("../../Assets/Animation/Blurr", false, /\.(png|jpe?g|svg)$/)
  );

  const [frameIndex, setFrameIndex] = useState(0);
  const [scrollDirection, setScrollDirection] = useState("down");
  const [scrollPosition, setScrollPosition] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const containerRef = useRef(null);
  const [bufferedFrames, setBufferedFrames] = useState([]);
  const [bufferedFramesBlurr, setBufferedFramesBlurr] = useState([]);

  useEffect(() => {

   // Download the first 20 frames to the client as a buffer
   const initialBuffer = imageList.slice(0, 20).map((image) => {
    const img = new Image();
    img.src = image;
    return img;
  });
  setBufferedFrames(initialBuffer);

   // Download the first 20 frames of imageListBlurr to the client as a buffer
   const initialBufferBlurr = imageListBlurr.slice(0, 20).map((image) => {
    const img = new Image();
    img.src = image;
    return img;
  });
  setBufferedFramesBlurr(initialBufferBlurr);

    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScrollDirection = () => {
      const currentScrollPosition = window.scrollY;

      if (currentScrollPosition > scrollPosition) {
        setScrollDirection("down");
      } else if (currentScrollPosition < scrollPosition) {
        setScrollDirection("up");
      }
    };

    handleScrollDirection();
  }, [scrollPosition]);

  useEffect(() => {
    const container = containerRef.current;
    const containerHeight = container.clientHeight;
    const containerTop = container.getBoundingClientRect().top;
    // const containerBottom = containerTop + containerHeight;
    const windowHeight = window.innerHeight;

    if (scrollDirection === "down") {
      const scrollOffset = containerTop - windowHeight + containerHeight;
      const frames = imageList.length;
      const frameHeight = (containerHeight / frames) * 6;
      let index = Math.floor((scrollPosition - scrollOffset) / frameHeight);
      index = index % frames; // Wrap index to the range of frames
      setFrameIndex(index);
    } else if (scrollDirection === "up") {
      const scrollOffset = containerTop - windowHeight;
      const frames = imageList.length;
      const frameHeight = (containerHeight / frames) * 6;
      let index = Math.floor((scrollPosition - scrollOffset) / frameHeight);
      index = index % frames; // Wrap index to the range of frames
      setFrameIndex(index);
    }
  }, [scrollPosition, scrollDirection, imageList.length]);

  const playAnimation = () => {
    setIsAnimating(true);
  };

  const stopAnimation = () => {
    setIsAnimating(false);
  };

  const reverseAnimation = () => {
    setIsAnimating(true);
    setFrameIndex(imageList.length - 1 - frameIndex);
  };

  return (
    <div className="animationContainer" ref={containerRef}>
      <img
        className="animationImg animationNoBlurr"
        src={imageList[frameIndex]}
        alt="Animation frame"
      />
      <img
        className="animationImg animationBlurr"
        src={imageListBlurr[frameIndex]}
        alt="Animation frame"
      />
    </div>
  );
};

function importAll(r) {
  return r.keys().map(r);
}

export default ScrollAnimation;
