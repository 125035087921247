import React, { useState, useEffect } from "react";
import "../Footer/Footer.css";

import { ReactComponent as Logo } from "../../Assets/logo.svg";

import facebook from "../../Assets/Icon/facebook.png";
import instagram from "../../Assets/Icon/instagram.png";
import twitter from "../../Assets/Icon/twitter.png";
import linkedin from "../../Assets/Icon/linkedin.png";

const Footer = () => {
  const [isDisabled, setIsDisabled] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 800);
      setIsDisabled(window.innerWidth < 800);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Set initial value

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <footer className="page-footer font-small blue">
      <div className="container-fluid text-center text-md-left">
        {isMobile ? (
          <div className="mobile">
            {/* <div className="col-md-2 mt-md-0 text-start">
              <Logo className="imgLogo" />
            </div> */}

            <div
              className={`col-md-6 ${
                isDisabled ? "text-start isDisabled" : "text-start"
              }`}
            ></div>

            <div className="col-md-12 mb-md-0 socialMediaIcon">
              <a href="#">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="#">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="#">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="#">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
            <div className="col-md-12  text-start">
              <h6>© 2022 Newto. All rights reserved.</h6>
            </div>
          </div>
        ) : (
          <div className="row isRemoved">
            <div className="col-md-2 mt-md-0 text-start">
              <Logo className="imgLogo" />
            </div>

            <div
              className={`col-md-6 ${
                isDisabled ? "text-start isDisabled" : "text-start"
              }`}
            >
              <h6>© 2022 Newto. All rights reserved.</h6>
            </div>

            <div className="col-md-4 mb-md-0 socialMediaIcon">
              <a href="#">
                <img src={facebook} alt="facebook" />
              </a>
              <a href="#">
                <img src={instagram} alt="instagram" />
              </a>
              <a href="#">
                <img src={twitter} alt="twitter" />
              </a>
              <a href="#">
                <img src={linkedin} alt="linkedin" />
              </a>
            </div>
          </div>
        )}
      </div>

      <div className="footer-copyright text-center py-1">
        {/* Your additional content */}
      </div>
    </footer>
  );
};

export default Footer;
