import { Link } from "react-router-dom";
import React, { useEffect, useRef } from "react";
import { Nav, Navbar, NavLink } from "react-bootstrap";
import style from "../NavBar/Navbar.module.scss";

import { ReactComponent as Logo } from "../../Assets/logo.svg";
import logoMobile from "../../Assets/logo.png";

const NavBarMenu = () => {
  const logoMobileRef = useRef(null);

  useEffect(() => {
    const navbarToggler = document.querySelector(".navbar-toggler");

    if (!logoMobileRef.current) {
      const logoMobileImg = document.createElement("img");
      logoMobileImg.src = logoMobile;
      logoMobileImg.alt = "Logo Mobile";
      logoMobileImg.classList.add("LogoMobile");
      navbarToggler.insertBefore(logoMobileImg, navbarToggler.firstChild);
      logoMobileRef.current = logoMobileImg;
    }
  }, []);

  //Scroll to the notification aria
  const handleContactLinkClick = () => {
    window.scrollTo(0, 2000);
    // Search for the input with class "notificationInput"
    setTimeout(()=>{
      const notificationInput = document.querySelector(".notificationInput");

      // Check if the input element is found
      if (notificationInput) {
        // Set focus on the input element
        notificationInput.focus();
  
        // Type some text into the input element
        notificationInput.value = "";
    }    
    },500);
  };

  return (
    <Navbar collapseOnSelect expand="sm" variant="dark" className="fixed-top">
      <Navbar.Toggle
        aria-controls="navbarScroll"
        data-bs-toggle="collapse"
        data-bs-target="#navbarScroll"
      />
      <Navbar.Collapse id="navbarScroll">
        <Nav className={style.navbarContainer}>
          <div className={style.navLinksLeft}>
            <NavLink eventKey="1" as={Link} to="/">
              <Logo />
            </NavLink>
          </div>
          <div className={style.navLinkRight}>
            <NavLink eventKey="3" as={Link} onClick={handleContactLinkClick}>
              contact
            </NavLink>
            {/* <NavLink eventKey="2" as={Link} to="/about">
              about us
            </NavLink> */}
          </div>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default NavBarMenu;
